import React from "react";
import StyledSidebar from "./Sidebar.styled";
import StyledSidebarInner from "./components/SidebarInner.styled";
import SidebarItem from "./components/SidebarItem";
import componentTypes from "@data/component-types";

interface SidebarProps {
  isActive?: boolean;
}

const Sidebar: React.FC<SidebarProps> = ({ isActive }) => {
  return (
    <StyledSidebar isActive={isActive}>
      <StyledSidebarInner>
        {componentTypes.map(item => {
          return <SidebarItem {...item} />;
        })}
      </StyledSidebarInner>
    </StyledSidebar>
  );
};

export default Sidebar;
