import styled from "styled-components";

const ComponentItem = styled.div`
  height: 300px;
  width: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 30px;
  margin-bottom: 30px;
  .gatsby-image-wrapper {
    height: 100%;
    width: 100%;
    border: 7px solid #0652dd;
  }
`;

export default ComponentItem;
