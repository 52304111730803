import styled from "styled-components";
import Link from "@components/Link";

const SidebarItem = styled(Link)`
  display: flex;
  align-items: center;
  color: #898a96;
  font-size: 1.4rem;
  margin-bottom: 20px;
  white-space: nowrap;
  transition: all 0.3s ease-in-out;
  text-decoration: none;
  &:hover {
    color: rgba(0, 0, 0, 0.6);
  }
`;

export default SidebarItem;
