import React, { useState } from "react";
import StyledComponentsType from "./ComponentsType.styled";
import Sidebar from "./components/Sidebar";
import Results from "./components/Results";
import Arrow from "./components/Arrow";

interface ComponentsTypeProps {
  components: any;
}

const ComponentsType: React.FC<ComponentsTypeProps> = ({
  components,
}) => {
  const [sidebarState, setSidebarState] = useState<boolean>(false);

  return (
    <StyledComponentsType>
      <Sidebar isActive={sidebarState} />
      <Arrow
        isActive={sidebarState}
        onClick={() => setSidebarState(!sidebarState)}
      />
      <Results components={components} />
    </StyledComponentsType>
  );
};

export default ComponentsType;
