import styled from "styled-components";
import media from "@styles/media";

const ResultsWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  padding-right: 70px;
  padding-top: 30px;
  width: 100%;
  ${media.toTabletP`
    padding: 20px;
  `}
`;

export default ResultsWrapper;
