import React from "react";
import StyledComponentItem from "./ComponentItem.styled";
import Image from "@components/Image";

interface ComponentItemProps {
  thumbnail?: any;
}

const ComponentItem: React.FC<ComponentItemProps> = ({
  thumbnail,
}) => {
  return (
    <StyledComponentItem>
      <Image src={thumbnail} alt="thumbnail" />
    </StyledComponentItem>
  );
};

export default ComponentItem;
