import React from "react";
import { IoIosArrowDown } from "react-icons/io";
import StyledArrow from "./Arrow.styled";

interface ArrowProps {
  isActive?: boolean;
  onClick?: (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
  ) => void;
}

const Arrow: React.FC<ArrowProps> = ({ isActive, onClick }) => {
  return (
    <StyledArrow onClick={onClick} isActive={isActive}>
      <IoIosArrowDown />
    </StyledArrow>
  );
};

export default Arrow;
