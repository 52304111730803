import styled, { css } from "styled-components";
import media from "@styles/media";

interface SidebarProps {
  isActive?: boolean;
}

const Sidebar = styled.div<SidebarProps>`
  transition: all 0.3s ease-in-out;
  transform: translate(0px);
  width: 300px;
  @media only screen and (max-width: 900px) {
    position: fixed;
    max-height: 100vh;
    overflow-y: scroll;
    background-color: #f5f5f5;
    width: 100vw;
    z-index: 10;
    transform: ${(props: SidebarProps) =>
      !props.isActive ? "translateY(-120%)" : "translateY(0)"};
  }
`;

export default Sidebar;
