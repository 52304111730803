import React from "react";
import SidebarItemLink from "./SidebarItem.styled";
import SidebarItemIcon from "./components/SidebarItemIcon.styled";
import SidebarItemText from "./components/SidebarItemText.styed";

interface SidebarItemProps {
  name?: string;
  Icon?: any;
}

const SidebarItem: React.FC<SidebarItemProps> = ({ Icon, name }) => {
  return (
    <SidebarItemLink to={`/components/${name.toLowerCase()}`}>
      <SidebarItemIcon>
        <Icon />
      </SidebarItemIcon>
      <SidebarItemText>{name}</SidebarItemText>
    </SidebarItemLink>
  );
};

export default SidebarItem;
