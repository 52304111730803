import React from "react";
import StyledResults from "./Results.styled";
import ComponentItem from "@components/ComponentItem";

interface ResultsProps {
  components?: any;
  onOpenModal?: any;
}

const Results: React.FC<ResultsProps> = ({ components }) => {
  return (
    <StyledResults>
      {components?.length ? (
        components?.map(item => <ComponentItem {...item.data} />)
      ) : (
        <div>No components found</div>
      )}
    </StyledResults>
  );
};

export default Results;
