import React from "react";
import { graphql } from "gatsby";

import ComponentsType from "@views/ComponentsType/ComponentsType";
import Layout from "../ui/layout";

interface ComponentTypePageProps {}

const ComponentTypePage: React.FC<ComponentTypePageProps> = props => {
  const components = props.data.allComponent.edges.map(
    (item: any) => item.node,
  );
  const { type } = props?.pathContext;

  return (
    <Layout
      header={{ color: "black" }}
      seo={{
        title: `Free ${type} designs For Inspiration (with React.js implementation)`,
        description: `A variety of react ${type} designs that will help boost your inspiration. Find creative designs that are implemented in react and include them now in your project for free!`,
      }}
    >
      <ComponentsType components={components} />
    </Layout>
  );
};

export const query = graphql`
  query ComponentTypeQuery($type: String!) {
    allComponent(filter: { data: { type: { eq: $type } } }) {
      edges {
        node {
          data {
            slug
            price
            preview
            name
            download
            type
            thumbnail {
              childImageSharp {
                fluid(maxWidth: 560, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default ComponentTypePage;
