import styled from "styled-components";
import media from "@styles/media";

interface ArrowProps {
  isActive?: boolean;
}

const Arrow = styled.div<ArrowProps>`
  height: 40px;
  width: 40px;
  background-color: #1a7ece;
  color: white;
  fill: white;
  position: fixed;
  top: 40px;
  right: 50%;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  font-size: 1.5rem;
  z-index: 1000;
  display: none;
  transition: all 0.3s ease-in-out;
  transform: translate(50%, 0);
  ${media.toTabletP`
    display: flex;
  `}
  transform: ${props =>
    props.isActive && `translate(50%, 0) rotate(180deg)`}
`;

export default Arrow;
